<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目审批</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="send-info">
            <div class="send-condition flex-center">
                <div class=" flex-center">
                    <el-input v-model="form" class="send-condition-item" placeholder="请输入项目名称"></el-input>
                    <el-button class="send-condition-item" type="primary" @click="searchList">搜索</el-button>
                </div>
                <div class="send-condition-state flex-center">
                    <span :class="{ active: state === 0 }" @click="state = 0">待办</span>
                    <span :class="{ active: state === 3 }" @click="state = 3">全部</span>
                    <span :class="{ active: state === 1 }" @click="state = 1">已通过</span>
                    <span :class="{ active: state === 2 }" @click="state = 2">已拒绝</span>
                </div>
            </div>

            <div class="send-list">
                <el-table
                    :data="tableData"
                    header-row-class-name="table-header"
                    style="width: 100%"
                    @row-click="toDetail">
                    <el-table-column label="审批标题" prop="title" width="240">
                        <template slot-scope="scope">
                            <div class="table-title flex-center">
                <span>{{
                        scope.row.true_name.substr(
                            scope.row.true_name.length - 2,
                            scope.row.true_name.length
                        )
                    }}</span>
                                <p>
                                    <b>{{ scope.row.true_name }}</b
                                    >提交的<b>项目信息变更申请</b>
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核摘要" prop="desc">
                        <template slot-scope="scope">
                            <div class="table-desc">
                                <p v-if="scope.row.name">
                                    <span>{{ $getTitleByType('会议名称', scope.row.cid) }}由</span>{{
                                        scope.row.last_name
                                    }}<span>变更为</span>{{ scope.row.name }}
                                </p>
                                <p v-if="scope.row.meet_form">
                                    <span>会议形式由</span>{{
                                        scope.row.last_meet_form | meet_form(scope.row.last_meet_info)
                                    }}<span>变更为</span>{{
                                        scope.row.meet_form | meet_form(scope.row.meet_info)
                                    }}
                                </p>
                                <p v-if="scope.row.sid">
                                    <span>所属学会由</span>{{
                                        scope.row.last_sid.join('、')
                                    }}<span>变更为</span>{{ scope.row.sid.join('、') }}
                                </p>
                                <p v-if="scope.row.start_time || scope.row.end_time">
                                    <span>{{
                                            $getTitleByType('会议日期', scope.row.cid)
                                        }}由</span>{{ scope.row.last_start_time + '至' + scope.row.last_end_time }}<span>变更为</span>{{
                                        (scope.row.start_time ? scope.row.start_time : scope.row.last_start_time) + '至' + (scope.row.end_time ? scope.row.end_time : last_end_time)
                                    }}
                                </p>
                                <p v-if="scope.row.last_other_info" v-html="scope.row.last_other_info"></p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="发起时间" prop="addtime" width="180">
                        <template slot-scope="scope">
                            <div
                                class="table-addtime"
                            >
                                {{ scope.row.create_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="完成时间" prop="endtime" width="180">
                        <template slot-scope="scope">
                            <div
                                class="table-endtime"
                            >
                                {{ scope.row.update_time ? scope.row.update_time : "" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="state" width="200">
                        <template slot-scope="scope">
                            <div class="table-state">
                                <p :class="{
                    active: scope.row.state === 3,
                    green:
                      scope.row.state === 2,
                  }">
                                    {{ scope.row.state | state }}
                                </p>

                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :current-page.sync="page"
                    :page-size="limit"
                    :total="pageTotal"
                    background
                    class="page-all"
                    layout="prev, pager, next"
                    @current-change="setPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {projectFroms} from "@/tools";

export default {
    name: "cc",
    data() {
        return {
            form: "",
            //0待审批 1已通过 2已拒绝 3全部
            state: 0,
            tableData: [],
            page: 1,
            pageTotal: 1,
            limit: 6,
        };
    },
    activated() {
        this.$emit("setIndex", [0, 5]);
        this.init();
    },
    methods: {
        /*去项目详情*/
        toDetail(row) {
            this.$router.push({
                name: "approvalProDetail",
                query: {id: row.id},
            });
        },
        // 分页变化
        setPage(val) {
            this.page = val;
            this.init();
        },
        searchList() {
            this.page = 1
            this.init();
        },
        //  页面加载时请求
        init() {
            this.$api.apiContent
                .getConfirmList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    page: this.page,
                    limit: this.limit,
                    name: this.form,
                    state: this.state,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            page: this.page,
                            limit: this.limit,
                            name: this.form,
                            state: this.state,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.data.map((item) => {
                        item.start_time = item.start_time
                            ? this.$tools.ZHDate(new Date(item.start_time * 1000))
                            : "";
                        item.end_time = item.end_time
                            ? this.$tools.ZHDate(new Date(item.end_time * 1000))
                            : "";
                        item.last_start_time = item.last_start_time
                            ? this.$tools.ZHDate(new Date(item.last_start_time * 1000))
                            : "";
                        item.last_end_time = item.last_end_time
                            ? this.$tools.ZHDate(new Date(item.last_end_time * 1000))
                            : "";
                    });
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch(() => {
                    this.tableData = [];
                    this.pageTotal = 0
                });
        },
    },
    watch: {
        $route: "init",
        state() {
            this.page = 1;
            this.init();
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    filters: {
        meet_form(type,other){
            let find = projectFroms.find(f=>{  return f.key === Number(type)})
            return find ? find.value + other : other
        },
        state(type) {
            // 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
            switch (Number(type)) {
                case 1:
                    return "已通过";
                case 2:
                    return "已拒绝";
                default:
                    return "审批中";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .send-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        overflow: hidden;

        .send-condition {
            padding: 24px 28px 16px;
            border-bottom: 2px solid #f5f5f5;

            .send-condition-item {
                margin-right: 20px;
            }

            .send-condition-state {
                border: 1px solid #3b77e7;
                border-radius: 4px;
                overflow: hidden;

                span {
                    display: block;
                    width: 120px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                    border-right: 1px solid #3b77e7;
                    cursor: pointer;

                    &:last-child {
                        border-right: 0;
                    }

                    &.active {
                        background: #3b77e7;
                        color: #fff;
                    }
                }
            }
        }

        .send-list {
            padding: 34px 28px;
            min-height: 600px;

            .table-title {
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 56px;
                    height: 56px;
                    border-radius: 56px;
                    text-align: center;
                    /*line-height: 24px;*/
                    font-size: 18px;
                    color: #fff;
                    background-color: #3b77e7;
                }

                p {
                    flex: 1;
                    margin-left: 10px;
                    font-size: 16px;
                    color: #000;

                    &.active {
                        color: #f03737;

                        b {
                            color: #f03737;
                        }
                    }

                    &.shenpi {
                        color: #f03737;

                        b {
                            color: #f03737;
                        }
                    }

                    b {
                        color: #3b77e7;
                        font-weight: normal;
                    }
                }
            }

            .table-desc {
                text-align: left;
                font-size: 16px;
                color: #666666;
                padding-left: 20px;

                &.active {
                    color: #f03737;
                }

                span {
                    color: #3b77e7;
                }
            }

            .table-addtime {
                text-align: center;
                font-size: 16px;
                color: #666666;

                &.active {
                    color: #f03737;
                }

                &.shenpi {
                    color: lightseagreen;
                }
            }

            .table-endtime {
                text-align: center;
                font-size: 16px;
                color: #666666;

                &.active {
                    color: #f03737;
                }

                &.shenpi {
                    color: lightseagreen;
                }
            }

            .table-state {
                display: flex;
                align-items: center;
                justify-content: space-around;
                text-align: center;
                font-size: 16px;
                position: relative;

                &.active2 {
                    background: url("../../assets/img/level2.png") no-repeat left center;
                    background-size: auto 100%;
                }

                &.active3 {
                    background: url("../../assets/img/level3.png") no-repeat left center;
                    background-size: auto 100%;
                }

                p {
                    min-height: 60px;
                    line-height: 60px;
                    color: #3b77e7;

                    &.active {
                        color: #f03737;
                    }

                    &.replied {
                        color: #795da3;
                    }

                    &.shenpi {
                        color: lightseagreen;
                    }

                    &.green {
                        color: #f03737;
                    }

                    .el-dropdown {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 26px;

                        img {
                            display: block;
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
            }

            .page-all {
                text-align: center;
                padding: 30px 0 0;
            }
        }
    }
}
</style>
